import React from 'react';
import { Helmet } from 'react-helmet';

import './index.css';

const pageTitle = 'Privacy Policy | Devetry';
const pageDescription = '';

const PrivacyTemplate = () => {
  return (
    <>
      <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta property="og:title" content={pageTitle} />
      </Helmet>
      <main className="root-container">
        <section className="not-found root-padding">
          <div>
            <h1>Privacy Policy</h1>
            <h3>Who we are</h3>
            <p>
              Devetry, Inc<br/>
              info@devetry.com<br/>
              3858 Walnut St #203<br/>
              Denver, CO 80205<br/>
            </p>

            <h3>What personal data we collect and why we collect it</h3>
            <p>
              When you submit a form on this site, that data is collected and stored
              in Hubspot and/or Salesforce for the purpose of improving sales and marketing efforts.
              Your visit (the pages you visit and actions you take on the website) is
              also anonymously tracked by Google Analytics, which provides aggregate analytics data
              to help improve the website. Your data is saved for an indeterminate amount of time,
              though you can request to have it removed by emailing info@devetry.com.
            </p>

            <h3>Cookies</h3>
            <p>
              By using the site, you consent to our use of cookies to remember user devices when
              they return to our site. This primarily refers to Google Analytics, which uses cookies
              to remember users and connect their behavior across sessions. That data is anonymized
              and can never be tied back to a specific user.
            </p>

            <h3>Embedded content from other websites</h3>
            <p>
              Articles on this site may include embedded content (e.g. videos, images, articles, etc.).
              Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.
            </p>
            <p>
              These websites may collect data about you, use cookies, embed additional
              third-party tracking, and monitor your interaction with that embedded content,
              including tracking your interaction with the embedded content if you have
              an account and are logged in to that website.
            </p>

            <h3>How we protect your data</h3>
            <p>
              This website and its forms are protected by SSL security. All locations
              where contact information is stored are likewise protected by security, including two factor authentication in some cases.
            </p>
          </div>
        </section>
      </main>
    </>
  );
};

const Privacy = () => {

  return <PrivacyTemplate />;
};

export default Privacy;
